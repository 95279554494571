@import "./../../index.scss";
.skills{
    display: inline-block;
    width: 100%;
    height: auto;
    padding-bottom:100px;
    box-sizing: border-box;
    &__inner{
        position: relative;
        max-width: 1280px;
        margin:0 auto;
        @media screen and (max-width: 1280px) {
            max-width: 90%;  
        }
    }
    &__list{
        &__item{
            margin-bottom: 20px;
        }
    }
    h2{
        @extend .f-h2;
    }
    ul,li{
        list-style: none;
    }
    ul{
        display: flex;
        flex-wrap: wrap;
    }
    li{
        flex: 1 0 33.33%;
        width:320px;
        @media screen and (max-width: 1280px) {
            flex: 1 0 50%;
            width: 150px;
        }
        ul{
            margin:0;
            padding:0;
        }
        li{
            padding-bottom:5px;
            flex:none;
            box-sizing: border-box;
        }
    }
}