@import "./../../index.scss";
.menu{
    position:fixed;
    top:20px;; 
    right: 20px;
    margin:20px;
    z-index: 100;
    ul,li{
      list-style: none;
    }
    &__container{
      position: fixed;
      width:250px;
      height:100vh;
      top:0;
      right:0;
      visibility: collapse;
      z-index:101;
      @media screen and (max-width: 800px) {
        background-color: $primary-color;
        width:100%;
      }
    }
    &__container.open-menu{
      visibility: visible;
      animation: pop-in-right 0.3s forwards;
    }
    &__list{
        position: relative;
        padding-top:40px;
        box-sizing: border-box;
      li{
        text-align: right;
        color:$third-color;
        @extend .f-h3 !optional;
        margin-right:30px;
        button{
          background: none;
          @extend .f-h3;
          border: none;
          text-decoration: none;
          cursor: pointer;
          &:hover,&:focus{
            text-decoration: underline;
          }
        }
      }
      li:first-of-type{
          margin-top:80px;
      }
    }
    &__icon{
        position: relative;
        width: 60px;
        height: 45px;
        visibility: hidden;
        overflow: hidden;
        animation:right-in 1s forwards;
        animation-delay: 1.4s;
    }
    &__external{
      position:relative;
      z-index: 103;
      li{
        width:50px;
        height:50px;
        float:right;
        margin-right:30px;
        a{
          position: absolute;
          width: 50px;
          height: 50px;
          z-index:105;
        }
        img{
          position: relative;
          width: 100%;
          height: auto;
          z-index:104;
        }
      }
    }
}
 #nav-icon {
    width: 60px;
    height: 45px;
    position: relative;
    margin-right: 20px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index:102;
    @media screen and (max-width: 800px) {
    }
    margin-right:5px;
  }
  
  #nav-icon span {
    display: block;
    position: absolute;
    height: 9px;
    width: 100%;
    background: $secondary-color;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
#nav-icon span:nth-child(1) {
    top: 0px;
  }
  
  #nav-icon span:nth-child(2),#nav-icon span:nth-child(3) {
    top: 18px;
  }
  
  #nav-icon span:nth-child(4) {
    top: 36px;
  }
  
  #nav-icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  
  #nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  #nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  #nav-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }