@import "../../index.scss";
.use-cases{
    height: auto;
    &__inner{
        position: relative;
        max-width: 1280px;
        margin: 0 auto;
        @media screen and (max-width: 1280px) {
            max-width: 90%;  
        }
    }
    h2{
        @extend .f-h2;
    }
    &__item{
        ul,li{
            list-style: none;
            margin:0;
            padding:0;
        }
        li{
            display: inline-block;
            width: 100%;
        }
        li:nth-child(even){
            .use-cases__item__text{
                float:right;
            }
        }
        &__text{
            float:left;
            width: 50%;
            padding:10px;
            box-sizing: border-box;
            @media screen and (max-width: 1280px) {
                width: 100%;
            }
        }
        &__image{
            float:left;
            width: 50%;
            padding:10px;
            box-sizing: border-box;
            @media screen and (max-width: 1280px) {
                float: none;;
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;
            }
            img{
                position:relative;
                width:100%;
                height: auto;
                @media screen and (max-width: 1280px) {
                    max-width: 600px;
                }
            }
        }
    }
}