.app {
  position:relative;
  width:100%;
  height:auto;
}

.footer{
  display: inline-block;
  width:100%;
  height: 40px;
  text-align: center;
  margin-bottom:2px;
}
