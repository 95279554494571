@import "./../../index.scss";
.skillset{
    display: inline-block;
    min-height:100vh;
    width: 100%;
    @media screen and (max-width: 1280px) {
        min-height: auto;
    }
    &__inner{
        max-width: 1280px;
        margin: 0 auto;
        @media screen and (max-width: 1280px) {
            max-width: 90%;  
        }
    }
    h2{
        @extend .f-h2;
        position: relative;
        display: inline-block;
        width:100%;
        height: auto;
    }
    &__container{
        display: inline-block;
        width: 100%;
    }
    &__section{
        display: inline-block;
        width: 100%;
        &__image{
            float:left;
            width: 50%;
            @media screen and (max-width: 800px) {
                float:none;
                width: 100%;
            }
            &.second{
                float:right;
            @media screen and (max-width: 800px) {
                float:none;
            }
            }
            img{
                padding-top:200px;
                @media screen and (max-width: 800px) {
                    padding:0;
                }
            }
        }
    }
    ul,li{
        margin:0;
        list-style: none;
    }
    &__list{
        position: relative;
        float:left;
        width:50%;
        float: left;
        padding:0px 50px;
        box-sizing: border-box;
        @media screen and (max-width: 900px) {
            width:100%;
            float: none;
            padding-bottom:10px;
            box-sizing: border-box;
        }
        @media screen and (max-width: 800px) {
            float:none;
            width: 100%;
            padding:0px 10px;
            padding-bottom:10px;
        }
        &__item{
            animation: pop-up-in 0.5s forwards;
            p{
                margin-bottom:5px;
            }
        }
    }
}