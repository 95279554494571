@import url('https://fonts.googleapis.com/css2?family=Lato&family=League+Spartan:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

$primary-color:#F9F5F1;
$secondary-color:#1E1C1C;
$third-color:#AD6A6C;
$cta-color:#00C49A;
$cta-hover-color:#156064;
$error:rgb(207, 12, 12);

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:$primary-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*Fonts*/
.f-h1{
  font-size: calc(100px + 3vmin);
  font-family: 'League Spartan', sans-serif;
  @media screen and (max-width: 1024px) {
    font-size: calc(60px + 2vmin);
  }
  @media screen and (max-width: 640px) {
    font-size: calc(40px + 2vmin);
  }
}
.f-h1-secondary{
  font-size: 4rem;
  font-weight: 300;
  @media screen and (max-width: 1024px) {
    font-size:3rem;
  }
}
.f-h2{
  font-family: 'League Spartan', sans-serif;
  font-size: 4rem;
  font-weight: 300;
  @media screen and (max-width: 1024px) {
    font-size:3rem;
  }
}
.f-h3{
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.f-h4{
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.f-main{
  font-size: 1rem;
}
.f-input{
  font-size: 1.2rem;
}
.f-small{
  font-size: 0.9rem;
}
/*Animations*/

@keyframes up-in {
  from {
    margin-top:100px;
    opacity: 0;
    overflow: hidden;
  }
  to {
    margin-top:0;
    opacity: 1;
    visibility: visible;
  }
}
@keyframes right-in {
  from {
    right:-200px;
    opacity: 0;
    overflow: hidden;
  }
  to {
    right:0;
    opacity: 1;
    visibility: visible;
  }
}
@keyframes pop-up-in{
  0%{
    transform: scale(0);
    opacity: 0;
  }
  90%{
    transform: scale(1.05);
    opacity: 1;
  }
  100%{
    transform: scale(1);
  }
}
@keyframes pop-in-right{
  0%{
    transform: translateX(100px);
    opacity: 0;
  }
  90%{
    transform: translateX(-5px);
    opacity: 1;
  }
  100%{
    transform: translateX(0);
  }
}

.animate-up-in{
  animation:  up-in 1s forwards;
}