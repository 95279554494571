@import './../../index.scss';
.about{
    display: inline-block;
    min-height:100vh;
    width: 100%;
    &__inner{
        position: relative;
        max-width: 1280px;
        margin: 0 auto;
        @media screen and (max-width: 1280px) {
            max-width: 90%;  
        }
    }
    &__text{
        position:absolute;
        padding-top:20vh;
        box-sizing: border-box;
        color:$secondary-color;
        text-align: left;
        width:auto;
        @media screen and (max-width: 800px) {
            position: relative;
            top:0;
            padding-left:0;
            translate:0%,0%;
            min-height:100vh;
            padding:20px;
            padding-top:100px;
            box-sizing: border-box;
        }
        h1{
            @extend .f-h1;
            visibility: hidden;
            animation:up-in 1s forwards;
            overflow: hidden;
            animation-delay: 1s;
        }
        h2{
            visibility: hidden;
            animation:up-in 1s forwards;
            overflow: hidden;
            animation-delay: 1.2s;
            @extend .f-h1-secondary;
        }
    }
}