@import "../../index.scss";
.experience{
  display: inline-block;
  width: 100%;
  min-height: auto;
  height: auto;
  &__inner{
    max-width: 1280px;
    margin: 0 auto;
    @media screen and (max-width: 1280px) {
        max-width: 90%;  
    }
  }
    ul,li{
        list-style: none;
    }
    h2{
        @extend .f-h2;
    }
}


:root {
  --up: 3;
  --card-margin: 12px;
  --card-shadow: rgba(46, 49, 52, .4);
  --color-white: #fff;
  --color-lightgrey: #ccc;
  --color-ui: #999;
  --color-ui-hover: #666;
  --cardWidth: calc(96vw/var(--up));
  --carouselWidth: calc(var(--cardWidth) * var(--num));
  --carouselLeftMargin: calc(2vw - var(--cardWidth));
  
  @media screen and (max-width: 900px) { /*basically mobile*/
    --up: 1;
  }
}

.card {
    box-shadow: 0px 1px 2px 0px var(--card-shadow);
    border-radius: 4px;
    margin: var(--card-margin);
    background:var(--color-white);
    text-align: left;
    height: fit-content;
    padding:20px;
    box-sizing: border-box;
    h3,h4,h5{
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      @extend .f-small;
    }
}

@keyframes next {
  from {
    transform:translateX(0px);
  }
  to {
    transform:translateX(calc(0px + var(--cardWidth)));
  }
}

@keyframes prev {
  from {
    transform:translateX(0px);
  }
  to {
    transform:translateX(calc(0px - var(--cardWidth)));
  }
}