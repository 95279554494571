
// import  npm install react-animation-on-scroll
@import './../../index.scss';
.clients{
    display: inline-block;
    width: 100%;
    &__inner{
        position: relative;
        max-width: 1280px;
        margin: 0 auto;
        @media screen and (max-width: 1280px) {
            max-width: 90%;  
        }
    }
    @media screen and (max-width: 800px) {
        padding-top:0;
    }
    &__text{
        h2{
            @extend .f-h2;
        }
    }
    &__list{
        position: relative;
        float:left;
        width:100%;
        margin:0;
        padding: 0;
        @media screen and (max-width: 1000px) {
            ul{
                display: flex;
                justify-content: center;
            }
        }
        &__item{
            position: relative;
            float:left;
            width:calc(25% - 40px);
            margin:20px;
            background-color: #fff;
            border-radius: 20px;
            padding: 20px 100px;
            box-sizing: border-box;
            height:100px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 1100px) {
                width:calc(33.33% - 40px);
            }
            @media screen and (max-width: 800px) {
                width:calc(50% - 40px);
            }
            @media screen and (max-width: 550px) {
                width:calc(100% - 10px);
                margin:5px;
            }
            img{
                max-width:100px;
                width: auto;
                height: auto;
                margin: 0 auto;
            }
        }
    }
}